function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function navigate(url){
  window.location.href = url
}

function openNewTab(url){
  window.open(url, '_blank')
}

function getCurUrl(){
  return window.location.href
}

function delay(time){
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

function scrollToError() {
  const element = document.getElementById('errorDiv');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    if (this.errorDiv && this.errorDiv.nativeElement) {
      this.errorDiv.nativeElement.focus();
    }
  }
}

function setupTextAreaMax() {
  var txts = document.getElementsByTagName('TEXTAREA');

  for (var i = 0, l = txts.length; i < l; i++) {
    if (/^[0-9]+$/.test(txts[i].getAttribute("maxlength"))) {
      var func = function () {
        var len = parseInt(this.getAttribute("maxlength"), 10);

        if (this.value.length > len) {
          alert('Maximum length exceeded: ' + len);
          this.value = this.value.substr(0, len);
          return false;
        }
      }

      txts[i].onkeyup = func;
      txts[i].onblur = func;
    }
  }
}
